<template>
  <div class="container">
    <!-- modals -->
    <AmbassadorTermsModal />
  </div>
</template>
<script>
import AmbassadorTermsModal from "@/components/Seller/Comissions/modals/AmbassadorTerms";

export default {
  name: "TermosComissoes",
  components: {
    AmbassadorTermsModal,
  },
  data() {
    return {
    };
  },
  computed: {
    ambassadorAcceptedTerms() {
      return this.$store.getters.getProfile.ambassador_accepted_terms;
    },
  },

  mounted() {
    if(this.ambassadorAcceptedTerms) {
      this.$router.push("/comissoes");
    } else {
      this.$bvModal.show("ambassador-terms");
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
